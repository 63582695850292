html {
  width:100%;
  height:100%;
  -webkit-text-size-adjust: none;
  font-family: 'Lato', sans-serif;
}

body {
  /* position: fixed; */
  margin: 0;
  padding: 0;
  width:100%;
  height:100%;
  /* overscroll-behavior-y: none; */
  /* overscroll-behavior-x: none; */
  /* overflow: hidden; */
  
}

.MuiButton-outlined{
  border:1px solid black !important;
}